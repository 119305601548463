.wrapper-home {
  position: absolute;
  top: 168px;
  left: 35px;
  text-align: center;

  h2 {
    font-size: 5rem;
  }

  a {
    font-size: 1.4rem;
  }
}

.footer-home {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  text-align: center;

  .link {
    width: 451px;
    height: 36px;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0.41px;
    text-align: center;
    color: #c1c1c1;
  }
}
@media screen and (max-width:1900px) {
  .wrapper-home {
    top: 120px;
    left: 40px;
  }
  .footer-home {
    height: 80px;
  }
}
