.wrapper-screen-5 {
  h1 {
    font-size: 30px;
    text-align: center;
  }

  h2 {
    height: 100%;
    width: 800px;
  }

  .wrapper-img {
    .img {
      text-align: left;
      padding: 30px 0 30px 725px;
      background: url("../../images/fuel-purchase/bg-element.svg") no-repeat;
      background-position-x: 695px;
      @media screen and (max-width:1900px) {
        padding: 20px 0 25px 532px;
        background-position-x: 513px;
        background-size: 247px;
      }
    }
    height: unset;
    margin: 60px auto;
    @media screen and (max-width:1900px) {
      margin: 34px auto;
    }

    h2 {
      text-align: left;
      width: 515px;
      color: #4a4a4a;
      font-weight: 500;
      @media screen and (max-width:1900px) {
        width: 324px;
      }
    }

    img {
      width: 243px;
      display: inline-block;
      @media screen and (max-width:1900px) {
        width: 153px;
      }
    }

    .full-tank {
      font-size: 30px;
      font-weight: 300;
      text-align: center;
      color: #929292;
      background: #fff;
      display: inline-block;
      vertical-align: top;
      margin: 45px 83px;
      width: 162.5px;
      height: 162.6px;
      border-radius: 50%;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px rgba(151, 151, 151, 0.5);
      @media screen and (max-width:1900px) {
        margin: 25px 60px;
        width: 102px;
        height: 102px;
        font-size: 17px;
      }



      img {
        width: 93px;
        padding: 45px 30px;
        @media screen and (max-width:1900px) {
          width: 68px;
          padding: 29px 17px;
        }
      }
    }
  }

  .wrapper-description {
    width: 1000px;
    margin: auto;
    @media screen and (max-width:1900px) {
      width: 700px;
    }

    h2 {
      width: unset;
      text-align: left;
      display: inline;
    }

    //.quantity {
    //  padding-bottom: 30px;
    //}

    .quantity,
    .sum,
    h2,
    .float-r {
      color: #c1c1c1;
      font-size: 30px;
      font-weight: 500;
      @media screen and (max-width:1900px) {
        font-size: 24px;
      }
    }

    .active {
      height: 48px;
      font-size: 40px;
      font-weight: 500;
      letter-spacing: 0.55px;
      color: #4a4a4a;
      @media screen and (max-width:1900px) {
        font-size: 32px;
      }

      h2,
      .float-r {
        font-size: 40px;
        font-weight: 500;
        color: #4a4a4a;
        @media screen and (max-width:1900px) {
          font-size: 32px;
        }
      }
    }
  }

  .wrapper-buttons {
    position: absolute;
    bottom: 30px;
    left: 310px;

    button:first-of-type {
      margin-right: 60px;
    }
  }
}

.wrapper-input-range {
  margin: auto;
  width: 1060px;

  .input-range {
    width: 1308px;
    padding: 0;
  }
}

.wrapper-slider-scale-large {
  background: repeating-linear-gradient(
      90deg,
      #777,
      #777 1px,
      transparent 1px,
      transparent 35px
    )
    no-repeat 50% 50%;
  background-position-x: -1px;
  background-size: 700px 16px;
  height: 30px;
}

.range {
  margin: 35px 80px;

  input[type="range"] {
    box-sizing: border-box;
    border: 0px solid transparent;
    height: 30px;
    padding: 0px;
    margin: 0px;
    width: 700px;
    cursor: pointer;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    box-sizing: border-box;
    width: 200px;
    height: 1px;
    background: #777;
  }

  input[type="range"]::-moz-range-track {
    box-sizing: border-box;
    width: 700px;
    padding: 0px;
    background: #777;
  }

  input[type="range"]::-moz-range-thumb {
    box-sizing: border-box;
    padding: 0px;
    width: 10px;
    background: #eee;
  }

  input[type="range"]::-ms-track {
    box-sizing: border-box;
    width: 700px;
    padding: 0px;
    background: #777;
    color: #777;
  }

  input[type="range"]::-webkit-slider-thumb {
    box-sizing: border-box;
    padding: 0px;
    margin-top: -45px;
    width: 60px;
    background-image: url("../../images/fuel-purchase/group.svg");
    background-position-x: -12px;
    background-position-y: 3px;
    cursor: pointer;
  }

  input[type="range"]::-ms-thumb {
    box-sizing: border-box;
    padding: 0px;
    width: 10px;
    background: #eee;
  }

  input[type="range"]::-ms-fill-lower {
    background: transparent;
  }

  input[type="range"]:focus {
    outline: none;
  }

  .tab-fuel:after {
    position: absolute;
    width: 750px;
    color: #777;
    content: "0 10 20 30 40 50 60 70 80 90 100";
    padding: 40px 0;
    word-spacing: 47px;
    left: 0px;
    top: 0px;
    z-index: -1;
  }

  .tab-sum:after {
    position: absolute;
    width: 750px;
    color: #777;
    content: "0 200 400 600 800 1000 1200 1400 1600 1800 2000";
    padding: 40px 0;
    word-spacing: 34px;
    left: 0px;
    top: 0px;
    z-index: -1;
  }

  .container {
    padding: 0px;
    position: relative;
  }
}

.slider {
  background: repeating-linear-gradient(
      90deg,
      #777,
      #777 1px,
      transparent 1px,
      transparent 70px
    )
    no-repeat 50% 50%;
  background-position-x: -0.5px;
  background-size: 700px 30px;
  -webkit-appearance: none;
  width: 700px;
  outline: none;
  padding: 0;
  border: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 90px;
  height: 90px;
  margin-right: -90px;
  background-image: url("../../images/fuel-purchase/group.png");
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 90px;
  height: 90px;
  background-image: url("../../images/fuel-purchase/group.svg");
  cursor: pointer;
}
