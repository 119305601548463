.button {
  width: 600px;
  height: 100px;
  border-radius: 48.5px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.4px;
  text-align: center;
  color: #4a4a4a;
  border: 0;
  outline: none;
}
.button:active {
  box-shadow: none;
  border: solid 1px #979797;
}
@media screen and (max-width:1900px) {
  .button {
    width: 520px;
    height: 96px;
  }
}