.modal {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(38, 38, 38, 0.5);
  z-index: 10;
  width: 100%;

  .modal-window {
    margin: 80px auto;
    text-align: center;
    background-color: #fff;
    width: 1300px;
    height: 1020px;
    border-radius: 30px;
    @media screen and (max-width:1900px) {
      width: 800px;
      height: 624px;
      img {
        width: 800px;
      }
    }


    .wrapper-content {
      margin: 45px 90px;
      @media screen and (max-width:1900px){
        margin: 32px 55px;
      }
      img {
        width: 43px;
      }

      h1 {
        width: 536px;
        height: 54px;
        margin-top: 15px;
        margin-bottom: 45px;
        @media screen and (max-width:1900px) {
          height: 30px;
          margin-top: 9px;
          margin-bottom: 34px;
        }
      }


      h2 {
        padding-top: 45px;
        font-weight: 300;
        color: #9b9b9b;
        width: 460px;
        height: 72px;
        font-size: 30px;
        font-weight: 300;
        letter-spacing: normal;
        @media screen and (max-width:1900px) {
          padding-top: 10px;
          font-size: 24px;
        }
      }

      .sum {
        text-align: left;
        width: 162px;
        height: 54px;
        font-size: 45px;
        font-weight: 500;
        color: #4a4a4a;
        @media screen and (max-width:1900px) {
          height: 38px;
          font-size: 24px;
        }
      }

      .grade {
        text-align: right;
        width: 118px;
        height: 36px;
        font-size: 30px;
        font-weight: 300;
        color: #4a4a4a;
        @media screen and (max-width:1900px) {
          font-size: 18px;
        }
      }

      .wrapper-scale {
        margin-bottom: 52px;
        @media screen and (max-width:1900px) {
          margin-bottom: 0;
        }
        .scale {
          display: inline-block;
          margin-top: 39px;
          margin-left: 48px;
          margin-right: 27px;
          @media screen and (max-width:1900px) {
            margin: 23px 15px;
          }

          .item {
            width: 20px;
            height: 20px;
            background-color: rgba(74, 74, 74, 0.1);
            border-radius: 50%;
            display: inline-block;
            margin-right: 35px;
            @media screen and (max-width:1900px) {
              width: 12px;
              height: 12px;
              margin-right: 20px;
            }

            &::last-of-type {
              margin-right: 0;
            }
          }

          .active {
            background-color: #00d369;
          }
        }

        .volume {
          height: 36px;
          font-size: 30px;
          font-weight: 300;
          color: #9b9b9b;
          display: inline-block;
        }
      }

      .line {
        width: 1300px;
        height: 2px;
        background-color: #d8d8d8;
        margin: 0 -90px;
        @media screen and (max-width:1900px) {
          width: 800px;
          margin: 0 -55px;
        }
      }
    }
  }
}
