.skip {
  padding-top: 49px;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.33px;
  text-align: right;
  color: #4a4a4a;
}
@media screen and (max-width:1900px) {
  .skip {
    padding-top: 30px;
  }
}