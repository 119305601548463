.container {
  margin: 0 19px;

  .wrapper {
    margin-top: 57px;
    @media screen and (max-width:1900px) {
      margin-top: 16px;
    }
    h2 {
      @media screen and (max-width:1900px) {
        font-size: 24px;
      }
    }
  }
}

.rectangle {
  width: 599.5px;
  height: 107.9px;
  border-radius: 18px;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 9px 13px 0 rgba(243, 100, 32, 0.2);
  @media screen and (max-width:1900px) {
    width: 479.6px;
    height: 86.3px;
    overflow: hidden;
  }

  .cardNumber {
    padding-top: 39px;
    padding-left: 36px;
    width: 222px;
    height: 29px;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.33px;
    color: #ffffff;
    @media screen and (max-width:1900px) {
      padding-top: 30px;
    }
  }

  .wrapperInfoLevel {
    position: absolute;
    left: 50px;
    top: 15px;
    height: 100px;
    @media screen and (max-width:1900px) {
      top: 3px;
      left: 30px;
    }


    .text {
      color: #fff;
      font-size: 30px;
      font-weight: 300;
      @media screen and (max-width:1900px) {
        font-size: 25px;
      }
    }

    .textSum {
      font-size: 40px;
      font-weight: 500;
    }
  }
}

.paymentLevel {
  display: block;
  width: 599.5px;
  height: 107.9px;
  box-shadow: 0 9px 13px 0 rgba(35, 47, 114, 0.2);
  background-image: radial-gradient(circle at 0 0, #000000, #2e3f98);
  border-radius: 20px;
}

.paymentMastercard {
  box-shadow: 0 9px 13px 0 rgba(243, 100, 32, 0.2);
  background-image: radial-gradient(circle at 0 0, #f13c17, #fac036);
}

.paymentVisa {
  box-shadow: 0 9px 13px 0 rgba(32, 101, 168, 0.2);
  background-image: radial-gradient(circle at 0 0, #1b599a, #2c81c6);
}

.wrapperImg {
  position: absolute;
  right: 36px;
  top: 24px;
  background-color: #fff;
  width: 88.7px;
  height: 60px;
  border-radius: 13.9px;
  @media screen and (max-width:1900px) {
    right: 30px;
    top: 14px;
  }


  img {
    display: block;
    margin: 0 auto;
  }

  .imgLevel {
    margin-top: 22px;
    width: 68.6px;
  }

  .imgVisa {
    margin-top: 20px;
    width: 68.6px;
  }

  .imgMastercard {
    width: 68.6px;
    margin-top: 10px;
  }
}

.wrapperInput {
  margin: 30px auto 57px;
  height: 56px;
  text-align: center;
  @media screen and (max-width:1900px) {
    margin: 8px auto;
    height: 50px;
  }

  input {
    letter-spacing: 57px;
    font-size: 45px;
    font-weight: bold;
    text-align: center;
    color: transparent;
    text-shadow: 0 0 0 #4a4a4a;
    border: none;


    &:focus {
      outline: none;
    }
  }
}

.wrapperButton {
  text-align: center;
}

.wrapperKeyboard {
  margin-top: 30px;
}
