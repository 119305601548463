.wrapper-service {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding-top: 115px;

  h1 {
    margin: 0 auto 60px;
    width: 599px;
    height: 108px;
    font-weight: 700;
    color: #4a4a4a;
  }

  h3 {
    text-align: center;
    color: #4a4a4a;
    width: 259px;
    height: 108px;
    font-weight: 500;
    margin: 0 auto;
  }

  .img-refuel-car {
    margin-top: 87.7px;
  }

  .img-menu-buta {
    margin-top: 87.7px;
    margin-bottom: 51px;
  }

  .button {
    height: 20;
  }
}
@media screen and (max-width:1900px) {
  .wrapper-service {
    padding-top: 32px;

   h1 {
     height: unset;
     padding-bottom: 48px;
     margin: 0 auto;
   }
  }
}