.wrapper-fuel-purchase {
  h2 {
    width: 528px;
    height: 72px;
    font-family: MuseoSansCyrl;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 0.41px;
    text-align: center;
    color: #c1c1c1;
  }

  .wrapper-img {
    text-align: center;
    height: 450px;
  }

  .wrapper-button {
    text-align: center;
    position: absolute;
    bottom: 30px;
    left: 660px;
  }
}

#react-root {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: darken(white, 20%);
}
@media screen and (max-width:1900px) {
  .wrapper-fuel-purchase{
    h2 {
      width: 585px;
      font-size: 24px;
    }
    .wrapper-button {
      left: 423px;
    }
    .wrapper-screen-2 .wrapper-img {
      height: 300px;
      img {
        height: 300px;
      }
    }
  }
}