.wrapper-type-payment {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding-top: 115px;

  h1 {
    margin-bottom: 90px;
    width: 571px;
    height: 54px;
    font-size: 45px;
    font-weight: bold;
  }

  h3 {
    width: 245px;
    height: 108px;
    font-size: 45px;
    font-weight: 500;
    margin: 0 auto;
  }

  .img-card {
    margin-top: 130px;
    width: 164px;
    height: 95px;
    margin-bottom: 26px;
  }

  .img-cash {
    margin-top: 120px;
    width: 108px;
    height: 131px;
  }

  .button {
    height: 20;
  }
}
@media screen and (max-width:1900px) {
  .wrapper-type-payment {
    padding-top: 32px;
    h1 {
      font-size: 32px;
      margin-bottom: 48px;
      height: auto;
    }
    h3 {
      font-size: 32px;
      width: 200px;
    }
    .img-card {
      margin: 80px 0 68px 0;
    }
    .img-cash {
      margin: 75px 0 37px 0;
    }
  }
}
