.header {
  margin: 0 80px;
  display: block;
  height: 128px;

  h2 {
    font-size: 5rem;
  }

  a {
    font-size: 1.4rem;
  }

  .float-l {
    width: 33.33%;
    height: 100%;
  }

  .title {
    height: 42px;
    margin-top: 43px;
    font-size: 35px;
    font-weight: 500;
    text-align: center;
    color: #4a4a4a;
  }
}
@media screen and (max-width:1900px)  {
  .header {
    margin: 0 48px;
    height: 88px;
    .title {
      font-size: 24px;
      margin-top: 31px;
    }
  }
}
