.wrapper-ingredient {
  display: inline-block;

  .item {
    width: 248px;
    height: 60px;
    border-radius: 10px;
    border: solid 1px rgba(146, 146, 146, 0.2);
    background-color: #ffffff;
    text-align: center;
    margin: 16px 16px 0 0;
  }

  .item img {
    width: 54px;
    padding: 3px 11px;
    float: left;
  }

  .item h3 {
    display: inline-block;
    padding-top: 19px;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #929292;
  }

  .shadow {
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1);
  }

  .plus {
    float: right;
    padding-top: 5px;

    img {
      width: 15px;
      padding: 18px;
    }
  }
}
