.product {
  h2 {
    font-size: 29px;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #4a4a4a;
  }

  img {
    display: inline-block;
    vertical-align: top;
    width: 640px;
    padding: 120px 130px;
  }

  .detail {
    display: inline-block;
    vertical-align: top;
    width: 920px;
  }

  .description {
    line-height: 1.5;
    padding-top: 5px;
    display: inline-block;
    width: 700px;
    font-size: 20px;
    font-weight: 500;
    color: #929292;
  }

  .price {
    font-size: 30px;
    color: #00d26a;
    font-weight: bold;
  }

  .weight {
    font-size: 20px;
    font-weight: 500;
    text-align: right;
    color: #929292;
  }

  hr {
    margin: 45px 0;
    height: 2px;
    border: none;
    background-color: #f2f2f2;
  }

  .ingredients .description,
  .supplements .description {
    font-size: 18px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.3px;
    color: #929292;
  }

  .choose {
    width: 981px;
  }

  .to-cart {
    text-align: center;
  }
}
@media screen and (max-width:1900px) {
  .product {
    h2 {
      font-size: 24px;
    }
    hr {
      margin: 20px 0;
    }
    img {
      width: 384px;
      padding: 48px 64px;
    }
    .detail {
      width: 790px;
    }
    .description {
      width: 538px;
      font-size: 18px;
    }
    .choose {
      width: 800px;
    }
    .to-cart {
      bottom: 20px;
      position: absolute;
      left: 423px;
    }
  }
}