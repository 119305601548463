.slider-container {
  width: 1850px;
  height: 756px;
  overflow: hidden;
  position: relative;
  border-radius: 15px;

  .active {
    position: absolute;
    background-color: #ffffff;
    opacity: 0.5;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    pointer-events: none;
  }

  .no-active {
    position: absolute;
    background-color: #ffffff;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    pointer-events: none;
  }
}

.slider-inner-container {
  transition: left 0.8s ease-out;
  display: flex;
  position: relative;
  height: 100%;

  & > * {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
  }

  & > * img {
    width: 100%;
    height: 100%;
  }
}

.slider-dots {
  position: absolute;
  text-align: center;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  color: white;

  div {
    cursor: pointer;
    width: 15px;
    height: 15px;
    padding: 10px;
    border-radius: 50%;
  }
}
 @media screen and (max-width:1900px) {
   .slider-container {
     width: 1286px;
     height: 515px;
   }
}
