.wrapper-register-line {
  .wrapper-img-1 {
    text-align: center;
    background-color: #e6e6e6;
    height: 0px;
    width: 658px;
    margin: 0 auto;

    .wrapper-img-2 {
      background-color: #00d26a;
      height: 0px;
      min-height: 0px;

      & > span {
        width: 1px;
        height: 1px;
        position: absolute;
        font-size: 25px;
        font-weight: bold;
        letter-spacing: 0.34px;
        text-align: center;
        color: #e6e6e6;
        margin-top: 22px;

        &.active {
          color: #00d26a;
        }

        &:first-of-type {
          margin-left: 41px;
        }

        &:nth-of-type(2) {
          margin-left: 150px;
        }

        &:nth-of-type(3) {
          margin-left: 262px;
        }

        &:nth-of-type(4) {
          margin-left: 371px;
        }

        &:nth-of-type(5) {
          margin-left: 484px;
        }

        &:last-of-type {
          margin-left: 592px;
        }
      }
    }
  }

  .height {
    height: 70px !important;
  }
}
