.simple-keyboard.hg-theme-default {
  background-color: #fff;
}

.react-simple-keyboard {
  .hg-row {
    .hg-button {
      height: 86px;
      border-radius: 9px;
      border: solid 1px #c1c1c1;
      background-color: #ffffff;

      span {
        font-size: 35px;
        font-weight: 500;
        letter-spacing: 0.48px;
        color: #4a4a4a;
      }
    }
  }

  .hg-row:last-of-type {
    width: 1009px;
    margin: 0 auto;
  }
}
@media screen and (max-width:1900px) {
  .react-simple-keyboard {
    .hg-row {
      .hg-button {
        height: 63px;
        span {
          font-size: 25px;
        }
      }
    }
  }
}